























































































































import { Component } from 'vue-property-decorator'
import { formatTimestamp } from '@/utils/utils'
import VueBase from '@/VueBase'
import request from '@/utils/request'

@Component({
  name: 'VulnDetail',
})
export default class VulnDetail extends VueBase {
  getReq(str: any) {
    return str
      .split('<')
      .join('&lt;')
      .split(`*`)
      .join('\\*')
      .split(`\n`)
      .join('<br/>')
  }
  fmtTime(time: any) {
    console.log('time', time)
    if (time) {
      const data = new Date(time).getTime()
      return formatTimestamp(data)
    }
    return ''
  }
  private settingInte: any = []
  private assetVulDetail: any = {}

  goBack = () => {
    this.$router.go(-1)
    // this.$router.push({
    //   name: 'vulnList',
    // })
  }
  private getlevel(leverid: any) {
    switch (leverid) {
      case 1:
        return '严重'
      case 2:
        return '高危'
      case 3:
        return '中危'
      case 4:
        return '低危'
      case 5:
        return '提示'
    }
  }
  private levelColor(level: any) {
    switch (level) {
      case '严重':
        return '#E56363'
      case '高危':
        return '#F49E0B'
      case '中危':
        return '#2F90EA'
      case '低危':
        return '#ABB2C0'
      case '提示':
        return '#DBDBDB'
    }
  }

  private statusOptions: Array<any> = [
    {
      value: this.$t('views.vulnDetail.reported'),
      label: this.$t('views.vulnDetail.reported'),
    },
    {
      value: this.$t('views.vulnDetail.confirmed'),
      label: this.$t('views.vulnDetail.confirmed'),
    },
    {
      value: this.$t('views.vulnDetail.fixed'),
      label: this.$t('views.vulnDetail.fixed'),
    },
    {
      value: this.$t('views.vulnDetail.ignored'),
      label: this.$t('views.vulnDetail.ignored'),
    },
  ]

  private tableData: Array<any> = []
  private page = 1
  private keyword = ''
  private selectedId = 0
  private total = 0

  private orderOptions = [
    {
      label: this.$t('views.vulnList.orderOptions.type'),
      value: 'type',
    },
    {
      label: this.$t('views.vulnList.orderOptions.level'),
      value: 'level',
    },
    {
      label: this.$t('views.vulnList.orderOptions.url'),
      value: 'url',
    },
    {
      label: this.$t('views.vulnList.orderOptions.latest_time'),
      value: 'latest_time',
    },
    {
      label: this.$t('views.vulnList.orderOptions.first_time'),
      value: 'first_time',
    },
  ]

  private syncInfo: any = {}
  private aggr_id: any = 0
  async init() {
    this.aggr_id = parseInt(this.$route.params.page)
    this.selectedId = parseInt(this.$route.params.id)
    await this.getAssetVulDetail()
  }
  async getAssetVulDetail() {
    const res = await this.services.vuln.getScanDetail(this.selectedId)
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.assetVulDetail = res.data
  }

  async created() {
    this.init()
  }
}
